(function($) {
"use strict";

$(document).ready(function($) {
	/* Navigation Toggle
	   ========================================================================== */

	$('.navigation-toggle').bind('click', function(e) {
	    e.preventDefault();
		$(this)
		.toggleClass('navigation-toggle-active')
		.closest('.navigation-responsive')
			.find('.navigation-collapse').toggleClass('navigation-open');
	});

	/* Slideshow Responsive
	   ========================================================================== */

	$(window).on("load resize", function () {

		$('.slideshow-responsive').each( function() {
			$('.slideshow-responsive .slide').css('min-height', '0px');
			var slider = $(this),
				innerWrapper = slider.find('.slide'),
				largest = Math.max.apply(null, innerWrapper.filter(':not(.cycle-sentinel)').map(function () {
					return $(this).innerHeight();
				}).get());
			innerWrapper.css('min-height', largest + 'px');
		});

	}).resize();

	jQuery(document).ready(function($) {

		/* HTML5 Placeholder
		   ========================================================================== */

		$('input, textarea').placeholder();

		/* Table Responsive
		   ========================================================================== */

		$('.hentry table, table.table-responsive').each(function () {
			var self = $(this);
			if (!self.parent().is('.table-responsive-wrap'))
				self.addClass('table-responsive').wrap('<div class="table-responsive-wrap"></div>');
		});

		/* Tabbed
		   ========================================================================== */

		$('.tabbed .tab')
			.hide()

		$('.tabbed .tabs li:first-child').addClass('active');

		$('.tabbed .active a').each(
			function() {
				$( $(this).attr('href') ).show();
			}
		);

		$('.tabbed .tabs li a').click( function() {

			var activeTab = $(this).closest('.tabs').find('.active a');

			$( $(activeTab).attr('href') ).hide();
			activeTab.parent().removeClass('active');
			$(this).parent().addClass('active');
			$( $(this).attr('href') ).fadeIn('slow');

			return false;
		});

	  /* Accordion
		   ========================================================================== */

		var accordion = '[data-accordion]';
		var	accordionHeader = '[data-accordion-header]';
		var	accordionContent = '[data-accordion-content]';
		var	accordionActiveClass = 'active';

		$(accordion)
		.find(accordionContent).hide().end()
		//.find(accordionHeader +':first').addClass(accordionActiveClass).end()
		//.find(accordionContent +':first').show().end()
		.on('click', accordionHeader, function(event) {
			var self = $(this);
			var	accordionBody = self.closest(accordion);

			if (!self.hasClass(accordionActiveClass)) {
				accordionBody
				.find(accordionHeader).removeClass(accordionActiveClass).end()
				.find(accordionContent).slideUp().end();

				self.addClass(accordionActiveClass)
				.next(accordionContent).slideDown(function() {
					$.fn.matchHeight._update(); // Update match height
				}).end();
			} else {
				//Allow for all accordions to collapse after being opened
				accordionBody
				.find(accordionHeader).removeClass(accordionActiveClass).end()
				.find(accordionContent).slideUp().end();
			}

			event.preventDefault();
		});

		/* Search Form
		   ========================================================================== */

		$( ".menu-site .menu-toggle" ).click(function() {
			$(this).toggleClass('menu-toggle-active').next().slideToggle( "fast", function() {
				$(this).toggleClass('menu-active')
			});
		});

		/* Search Form
		   ========================================================================== */

		$('.header-search-form, .header-search-form input').click(function(e) {
			$('.header-search-form fieldset').show('fast');
			$(".header-search-form .search-form-input").focus()
		});

		$('.header-search-form').mouseleave(function() {
			if ($('.header-search-form fieldset').css("display") === "block") {
				$('.header-search-form fieldset').hide('fast');
			}
		});
	});
});

}(jQuery));
